@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300&family=Cabin+Sketch:wght@400;700&family=Fredericka+the+Great&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300&family=Cabin+Sketch:wght@400;700&family=Days+One&family=Fredericka+the+Great&family=Kalnia:wght@600&family=Rubik+Lines&display=swap");

.text-head {
  text-align: center;
  color: #00bffb;
  font-family: "Days One";
}

.text_color {
  color: #02dbea;
}

.bg_btn {
  background-color: #02dbea;
}

.text_color_p {
  color: #6e7576;
}

.banner-container {
  position: relative;
  overflow: hidden;
}

.center_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.line_boxs {
  max-width: 440px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.line_boxes {
  padding: 60px 20px;
  max-width: 350px;
  position: relative;
  cursor: pointer;
}

.marquee-items {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.marquee {
  display: flex;
  width: 200%;
  animation: marqueeSlide 20s linear infinite;
}

.marquees {
  display: flex;
  width: 200%;
  animation: marqueeSlides 20s linear infinite;
}

.btn_footer {
  background-color: #1a7a7e;
  padding: 8px 25px;
  cursor: pointer;
  font-weight: 600;
}

.social_icons {
  background-color: #00c0fbd1;
  padding: 12px;
  border-radius: 50%;
}

.social_icons:hover {
  background-color: #00c0fbec;
}

.your-element {
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 2px solid rgba(128, 128, 128, 0);
}

.your-element:hover img {
  transform: rotateY(360deg);
  transition: 0.8s;
}

.your-element:hover {
  border: 2px solid #0a98a2;
  transition: 0.5s;
}

.hover_effect:hover h1 {
  color: #00737b;
  transition: 0.5s;
}

.lets_start {
  height: 400px;
  min-width: 100%;
  background-color: #11a1bd;
  border-radius: 20px;
}

.content_inner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btns {
  background-color: black;
  padding: 20px 30px;
  color: white;
  border-radius: 50px;
}

.certificate {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 40px 45px;
  border-radius: 50%;
}

.cources {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  padding: 50px;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  font-family: Days One;
  align-items: center;
}

.cources img {
  height: 100px;
}

.paracontent {
  width: 100%;
}

.bg_client {
  background-color: #1f2937;
  border-radius: 10px;
  color: #5b6471;
}

.custom-btn {
  width: 180px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.btn-5 {
  width: 300px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  background: rgb(255, 27, 0);
  background: #12a0be;
}

.btn-5:hover {
  color: #12a0be;
  background: transparent;
  box-shadow: none;
}

.btn-5:before,
.btn-5:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #12a0be;
  box-shadow: -1px -1px 5px 0px #fff, 7px 7px 20px 0px #0003,
    4px 4px 5px 0px #0002;
  transition: 400ms ease all;
}

.btn-5:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.btn-5:hover:before,
.btn-5:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

.btn-16 {
  border: none;
  color: #000000;
}

.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}

.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.btn-16:active {
  top: 2px;
}

.back_bg_figure {
  background-color: #1f2937;
}

.h_scrren {
  height: 100%;
  width: 100%;
}

.contact_box {
  height: 300px;
  width: 450px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.contact_icon {
  background-color: #15c1cd3e;
  padding: 10px;
  border-radius: 50%;
  color: #15c1cd;
}

.fourth_side {
  margin-top: 0px;
}

@keyframes marqueeSlide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes marqueeSlides {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

/* Responsive Styles */
@media (max-width: 1025px) {
  .marquee,
  .marquees {
    width: 300%;
  }

  @keyframes marqueeSlide {
    to {
      transform: translateX(-25%);
    }
  }

  @keyframes marqueeSlides {
    from {
      transform: translateX(-25%);
    }
    to {
      transform: translateX(0);
    }
  }
}

@media (max-width: 768px) {
  .marquee,
  .marquees {
    width: 300%;
  }

  @keyframes marqueeSlide {
    to {
      transform: translateX(-25%);
    }
  }

  @keyframes marqueeSlides {
    from {
      transform: translateX(-25%);
    }
    to {
      transform: translateX(0);
    }
  }
}

@media (max-width: 480px) {
  .marquee,
  .marquees {
    width: 300%;
  }

  @keyframes marqueeSlide {
    to {
      transform: translateX(-16.67%);
    }
  }

  @keyframes marqueeSlides {
    from {
      transform: translateX(-16.67%);
    }
    to {
      transform: translateX(0);
    }
  }
}

.bgColorDark {
  background-color: rgb(31, 41, 55);
}

@media screen and (min-width: 325px) and (max-width: 480px) {
  .custom-btn {
    width: 120px;
    padding: 10px 0px;
  }

  .fourth_side {
    margin-top: 40px;
  }

  .image_service img {
    height: 130px;
    width: auto;
  }

  .btn-5 {
    width: 270px;
    line-height: 42px;
    padding: 0;
  }

  .cources {
    height: 140px;
    width: 140px;
  }

  .cources img {
    height: 40px;
  }
}

@media screen and (min-width: 481px) and (max-width: 639px) {
  .manual_class {
    max-width: 450px;
    margin: 0 auto;
  }

  .image_service img {
    height: 130px;
    width: auto;
  }

  .fourth_side {
    margin-top: 40px;
  }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
  .image_service img {
    height: 80px;
    width: auto;
  }

  .fourth_side {
    margin-top: 40px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .image_service img {
    height: 100px;
    width: auto;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1240px) {
  .image_service img {
    height: 80px;
    width: auto;
  }

  .fourth_side {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1241px) and (max-width: 1536px) {
  .image_service img {
    height: 80px;
    width: auto;
  }

  .footer_margin {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1537px) {
  .footer_margin {
    margin-top: 0px;
  }
}
